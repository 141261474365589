import api from '../api';
import { createFormData } from '../../helpers';

const state = {
  users: [],
  groups: [],
};

const getters = {
  users: ({ users }) => users,
  groups: ({ groups }) => groups,
};

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
  SET_GROUPS: (state, payload) => {
    state.groups = payload;
  },
};

const actions = {
  fetchCurrentUsers: ({ commit }, {
    searchTerm, ordering, page, pageSize,
  }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api.get(`api/v1/users?search=${searchTerm || ''}&ordering=${ordering || ''}&page=${page}&page_size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('SET_USERS', data);
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },
  fetchCurrentGroups: ({ commit }, {
    searchTerm, ordering, page, pageSize,
  }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api.get(`api/v1/groups?search=${searchTerm || ''}&ordering=${ordering || ''}&page=${page}&page_size=${pageSize}&search_admin=true`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('SET_GROUPS', data);
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },
  fetchUser: ({ commit }, userId) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api.get(`api/v1/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },
  saveUser: ({ commit }, user) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    const {
      firstName,
      lastName,
      city,
      state,
      country,
      showEmail,
      link,
      bio,
      isAttendingNextEvent,
      profilePicture,
      previousFirstName,
      previousLastName,
      school,
      major,
      employer,
      status,
      receiveAdminEmails,
      socialMedia,
      email,
    } = user;
    const finalData = {};
    finalData.firstName = firstName;
    finalData.lastName = lastName;
    finalData.showEmail = showEmail;
    finalData.email = email;
    finalData.isAttendingNextEvent = isAttendingNextEvent;
    finalData.receiveAdminEmails = receiveAdminEmails;
    finalData.username = email;

    if (status) {
      finalData.status = status;
    }

    const bioToPost = bio || '';
    finalData.bio = bioToPost;
    const previousFirstNameToPost = previousFirstName || '';
    finalData.previousFirstName = previousFirstNameToPost;
    const previousLastNameToPost = previousLastName || '';
    finalData.previousLastName = previousLastNameToPost;
    const schoolToPost = school || '';
    finalData.school = schoolToPost;
    const majorToPost = major || '';
    finalData.major = majorToPost;
    const employerToPost = employer || '';
    finalData.employer = employerToPost;
    const cityToPost = city || '';
    finalData.city = cityToPost;
    const stateToPost = state || '';
    finalData.state = stateToPost;
    const countryToPost = country || '';
    finalData.country = countryToPost;

    const linkToPost = link || '';
    finalData.link = linkToPost;

    // these don't get done automagically
    const finalSocial = [];
    if (socialMedia) {
      socialMedia.forEach((element) => {
        finalSocial.push({ media_type: element.mediaType, url: element.url });
      });
    }
    const form_data = new FormData();
    createFormData(finalData, form_data);
    form_data.append('social_media', `{"social_media":${JSON.stringify(finalSocial)}}`);

    if (profilePicture && typeof profilePicture !== 'string') {
      form_data.append('profilePicture', profilePicture, 'profilephoto.jpg');
    }


    return api.patch(`api/v1/users/${user.id}/`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return {
          success: true,
          profile: data,
        };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  deleteUser: ({ commit }, userId) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api.delete(`api/v1/users/${userId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },
  fetchGroupUsers: ({ commit }, {
    slug, searchTerm, ordering, page, pageSize,
  }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api.get(`api/v1/groups/${slug}/users/?search=${searchTerm || ''}&ordering=${ordering || ''}&page=${page}&page_size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        commit('SET_USERS', data);
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return Promise.reject();
      });
  },


};

export default {
  state,
  getters,
  actions,
  mutations,
};
