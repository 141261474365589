import qs from 'qs';
import { createFormData } from '@/helpers';
import { pick } from '@/helpers/object.helper';
import api from '../api';

const state = {
  currentProfile: {},
  profiles: [],
  basicProfiles: [],
  group: {},
  inviteModalOpen: false,
  welcomeModalOpen: false,
  profileFields: [],
  selfGroupProfiles: [],
  groupFeatureFlags: null,
  settingsGroupFeatureFlags: null,
};

const getters = {
  profiles: ({ profiles }) => profiles,
  basicProfiles: ({ basicProfiles }) => basicProfiles,
  currentProfile: ({ currentProfile }) => currentProfile,
  group: ({ group }) => group,
  inviteModalOpen: ({ inviteModalOpen }) => inviteModalOpen,
  welcomeModalOpen: ({ welcomeModalOpen }) => welcomeModalOpen,
  profileFields: ({ profileFields }) => profileFields,
  selfGroupProfiles: ({ selfGroupProfiles }) => selfGroupProfiles,
  groupFeatureFlags: ({ groupFeatureFlags }) => groupFeatureFlags,
  settingsGroupFeatureFlags: ({ settingsGroupFeatureFlags }) => settingsGroupFeatureFlags,
};

const mutations = {
  SET_GROUP: (state, payload) => {
    state.group = payload;
  },
  SET_PROFILES: (state, payload) => {
    state.profiles = payload;
  },
  SET_BASIC_PROFILES: (state, payload) => {
    state.basicProfiles = payload;
  },
  SET_CURRENT_PROFILE: (state, payload) => {
    state.currentProfile = payload;
  },
  UPDATE_MODAL_OPEN: (state, payload) => {
    state.inviteModalOpen = payload;
  },
  UPDATE_WELCOME_MODAL_OPEN: (state, payload) => {
    state.welcomeModalOpen = payload;
  },
  SET_PROFILE_FIELDS: (state, payload) => {
    state.profileFields = payload;
  },
  SET_SELF_GROUP_PROFILES: (state, payload) => {
    state.selfGroupProfiles = payload;
  },
  SET_GROUP_FEATURE: (state, payload) => {
    state.groupFeatureFlags = payload;
  },
  SET_SETTINGS_GROUP_FEATURE: (state, payload) => {
    state.settingsGroupFeatureFlags = payload;
  },
};

const actions = {
  sendGroupMessage: ({ commit }, { groupSlug, message }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post(
        `api/v1/groups/${groupSlug}/send_message/`,
        { message },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  removeUserFromGroup: ({ commit }, { groupSlug, profileSlug }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .delete(`api/v1/groups/${groupSlug}/profile/${profileSlug}/membership/`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ status }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { status };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  updateModalOpen: ({ commit }, inviteModalOpen) => {
    commit('UPDATE_MODAL_OPEN', inviteModalOpen);
  },

  updateWelcomeModalOpen: ({ commit }, welcomeModalOpen) => {
    commit('UPDATE_WELCOME_MODAL_OPEN', welcomeModalOpen);
  },

  updateGroup: ({ commit, dispatch }, { groupSlug, group }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });

    let groupPhoto = null;
    if (group) {
      groupPhoto = group.groupPhoto;
      delete group.groupPhoto;
    }

    if (groupPhoto && typeof groupPhoto !== 'string') {
      return dispatch('updateGroupPhoto', { groupSlug, photoBlob: groupPhoto }).then(() => {
        return api
          .patch(`api/v1/groups/${groupSlug}/`, group, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(({ data }) => {
            commit('UPDATE_IS_LOADING', false, { root: true });
            commit('SET_GROUP', data);
            return { success: true, group: data };
          })
          .catch(() => {
            commit('UPDATE_IS_LOADING', false, { root: true });
            return { success: false };
          });
      });
    }

    return api
      .patch(`api/v1/groups/${groupSlug}/`, group, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_GROUP', data);
        return { success: true, group: data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        const errors = error && error.response && error.response.data;
        return { success: false, errors };
      });
  },

  updateGroupPhoto: ({ commit }, { groupSlug, photoBlob }) => {
    const token = localStorage.getItem('access-token');
    const formData = new FormData();
    formData.append('groupPhoto', photoBlob, 'groupPhoto.jpg');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .patch(`api/v1/groups/${groupSlug}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_GROUP', data);
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
  uploadGroupFile: ({ commit }, { groupSlug, file }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post(`api/v1/groups/${groupSlug}/files/`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, url: data.file };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchGroup: ({ commit }, { groupSlug }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });

    return api
      .get(`api/v1/groups/${groupSlug}/`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_GROUP', data);
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchSelfGroupProfiles: ({ commit }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });

    return api
      .get('api/v1/groups/self', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_SELF_GROUP_PROFILES', data.results);
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchProfiles: ({ commit }, { groupSlug, sort, filters, size, page }) => {
    const token = localStorage.getItem('access-token');
    const pagination = {
      page_size: size,
      page,
    };

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api
      .get(
        `api/v1/groups/${groupSlug}/profiles/?${qs.stringify(pagination)}${qs.stringify(
          { sort, ...filters },
          { strictNullHandling: true },
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_PROFILES', data.results);
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchBasicProfiles: ({ commit }, { groupSlug, sort, filters }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api
      .get(
        `api/v1/groups/${groupSlug}/basicProfiles/?${qs.stringify(
          { sort, ...filters },
          { strictNullHandling: true },
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_BASIC_PROFILES', data.results);
        return { success: true, data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  fetchCurrentUsersProfile(_, { groupSlug, profileSlug }) {
    // TODO: this can be removed after the video array is added to the current user
    const token = localStorage.getItem('access-token');

    return api
      .get(`api/v1/groups/${groupSlug}/profiles/${profileSlug}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return { success: true, data };
      })
      .catch(() => {
        return { success: false };
      });
  },

  fetchProfile: ({ commit }, { groupSlug, profileSlug }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api
      .get(`api/v1/groups/${groupSlug}/profiles/${profileSlug}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_CURRENT_PROFILE', data);
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  addProfileToList: ({ commit }, { groupSlug, profileSlug }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .post(`api/v1/groups/${groupSlug}/profile/${profileSlug}/my_list/`, null, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, profile: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  removeProfileFromList: ({ commit }, { groupSlug, profileSlug }) => {
    const token = localStorage.getItem('access-token');
    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .delete(`api/v1/groups/${groupSlug}/profile/${profileSlug}/my_list/`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, profile: data };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  sendGroupInvite: ({ commit }, { groupSlug, emails, message }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');

    return api
      .post(
        `api/v1/groups/${groupSlug}/invite_users/`,
        {
          emails,
          message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        // fyi: this endpoint doesn't return anything other than a status code
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  createGroup: ({ commit }, body) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const { groupPhoto } = body;
    delete body.groupPhoto;
    const formData = new FormData();
    if (groupPhoto) {
      formData.append('groupPhoto', groupPhoto, 'groupPhoto.jpg');
    }
    createFormData(body, formData);
    return api
      .post('api/v1/groups/', formData, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_GROUP', data);
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  joinGroup: ({ commit }, { groupSlug, profileSlug, body }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');

    const { isAttendingNextEvent } = body;

    const finalData = new FormData();

    finalData.append('isAttendingNextEvent', isAttendingNextEvent);

    return api
      .post(`api/v1/groups/${groupSlug}/profile/${profileSlug}/membership/`, finalData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  createProfileFieldValue: ({ commit }, { groupSlug, customFieldSlug, value, author }) => {
    if (!value) {
      return Promise.resolve;
    }
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const postData = { value };
    if (author) {
      postData.author = author;
    }
    return api
      .post(
        `api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}/value/`,
        postData,
        config,
      )
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  updateProfileFieldValue: ({ commit }, { groupSlug, customFieldSlug, value, author }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const postData = { value };
    if (author) {
      postData.author = author;
    }
    if (value) {
      return api
        .put(
          `api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}/value/`,
          postData,
          config,
        )
        .then(({ data }) => {
          commit('UPDATE_IS_LOADING', false, { root: true });
          return { success: true, data };
        })
        .catch((error) => {
          commit('UPDATE_IS_LOADING', false, { root: true });
          return { success: false, error };
        });
    }

    return api
      .delete(`api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}/value/`, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  setGroupAdmin: ({ commit }, { groupSlug, isAdmin, userId }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const body = {
      is_admin: isAdmin,
    };

    return api
      .patch(`api/v1/groups/${groupSlug}/users/${userId}/`, body, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  setNotificationsEnabled: ({ commit }, { groupSlug, notificationsEnabled, userId }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const body = {
      notifications_enabled: notificationsEnabled,
    };

    return api
      .patch(`api/v1/groups/${groupSlug}/users/${userId}/`, body, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  removeProfileFieldValue: ({ commit }, { groupSlug, customFieldSlug }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return api
      .delete(`api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}/value/`, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  fetchProfileFields: ({ commit }, { groupSlug }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    commit('CLEAR_ERRORS', true, { root: true });

    return api
      .get(`api/v1/groups/${groupSlug}/custom-group-fields/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        commit('SET_PROFILE_FIELDS', data.results);
        return { success: true };
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },

  createProfileField: ({ commit }, { groupSlug, body }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return api
      .post(`api/v1/groups/${groupSlug}/custom-group-fields/`, body, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  updateProfileField: ({ commit }, { groupSlug, body, customFieldSlug }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    const token = localStorage.getItem('access-token');
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return api
      .put(`api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}/`, body, config)
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: true, data };
      })
      .catch((error) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false, error };
      });
  },

  deleteProfileField: ({ commit }, { groupSlug, customFieldSlug }) => {
    const token = localStorage.getItem('access-token');

    commit('UPDATE_IS_LOADING', true, { root: true });
    return api
      .delete(`api/v1/groups/${groupSlug}/custom-group-fields/${customFieldSlug}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
      });
  },

  fetchFeatureFlags: async ({ commit }, groupSlug) => {
    const token = localStorage.getItem('access-token');
    const baseHeader = { Authorization: `Bearer ${token}` };
    try {
      const response = await api.get(`api/v1/${groupSlug}/feature-flags/`, {
        headers: {
          ...baseHeader,
        },
      });
      commit('SET_GROUP_FEATURE', response.data);
    } catch (error) {
      return { success: false, error };
    }
  },

  fetchSettingFeatureFlags: async ({ commit }, groupId) => {
    const token = localStorage.getItem('access-token');
    const baseHeader = { Authorization: `Bearer ${token}` };
    try {
      const response = await api.get(`api/v1/groups/${groupId}/config/`, {
        headers: {
          ...baseHeader,
        },
      });
      commit('SET_SETTINGS_GROUP_FEATURE', response.data);
    } catch (error) {
      console.log(error);
    }
  },

  updateSettingFeatureFlags: async (
    { commit, dispatch },
    {
      groupId,
      groupSlug,
      isGroupPhotosEnabled,
      isMessageBoardEnabled,
      isOffensiveContentFlaggingEnabled,
      isAiIntegrationEnabled,
      isMemberNotificationsEnabled,
      isCreatedByAdminLabelEnabled,
    },
  ) => {
    const body = {
      is_group_photos_enabled: isGroupPhotosEnabled,
      is_message_board_enabled: isMessageBoardEnabled,
      is_offensive_content_flagging_enabled: isOffensiveContentFlaggingEnabled,
      is_ai_integration_enabled: isAiIntegrationEnabled,
      is_member_notifications_enabled: isMemberNotificationsEnabled,
      is_created_by_admin_label_enabled: isCreatedByAdminLabelEnabled,
    };

    try {
      const token = localStorage.getItem('access-token');
      const baseHeader = { Authorization: `Bearer ${token}` };
      const response = await api.patch(`api/v1/groups/${groupId}/config/`, body, {
        headers: {
          ...baseHeader,
        },
      });
      commit('SET_SETTINGS_GROUP_FEATURE', response.data);
      dispatch('fetchFeatureFlags', groupSlug);
    } catch (error) {
      return { success: false, error };
    }
  },

  createNewMember: ({ commit }, { newMember, slug }) => {
    commit('UPDATE_IS_LOADING', true, { root: true });

    const token = localStorage.getItem('access-token');
    const socialMedia = [];
    if (newMember.instagram.url) socialMedia.push(newMember.instagram);
    if (newMember.facebook.url) socialMedia.push(newMember.facebook);
    if (newMember.linkedin.url) socialMedia.push(newMember.linkedin);
    if (newMember.twitter.url) socialMedia.push(newMember.twitter);

    const form_data = new FormData();
    const finalData = pick(
      newMember,
      'lastName',
      'firstName',
      'isDeceased',
      'city',
      'state',
      'country',
      'employer',
      'major',
      'bio',
      'phoneNumber',
      'link',
      'dateOfDeath',
    );

    const js_data = { ...finalData, socialMedia };

    if (newMember.profilePicture) {
      createFormData(finalData, form_data);
      form_data.append('social_media', JSON.stringify(socialMedia));
      form_data.append('profilePicture', newMember.profilePicture, 'profilephoto.jpg');
    }

    return api
      .post(
        `api/v1/groups/${slug}/admin-managed-profile/`,
        newMember.profilePicture ? form_data : js_data,
        {
          headers: {
            'Content-Type': newMember.profilePicture ? 'multipart/form-data' : 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(({ data }) => {
        commit('UPDATE_IS_LOADING', false, { root: true });
      })
      .catch(() => {
        commit('UPDATE_IS_LOADING', false, { root: true });
        return { success: false };
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
